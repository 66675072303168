:root {
  font-size: 16px;


  --text-primary: rgb(39, 48, 67);
  --text-secondary: rgb(239, 246, 238);
  --bg-primary: rgb(39, 48, 67);
  --bg-secondary: #141418;
  --red: rgb(240, 45, 58);
  --red-fade: rgb(240, 45, 58);
  --bg-neutral: rgb(239, 246, 238);
  --transition-speed: 600ms;
}

body {
  color: black;
  // background-color: white;
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Montserrat', sans-serif;
  overscroll-behavior: none;
}

.particles {
  position: fixed;
  right: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  z-index: -1;
  background-color: #2E294E;
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: var(--bg-secondary);
}

main {
  width: 70vw;
  max-width: 80em;
  margin-left: 5rem;
  padding: 1rem;
  margin: 0 auto;

  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
  }
}

.container {
  padding: 1.5rem;
  margin: 2em 0;
  border-radius: 10px;
  background-image: linear-gradient(-128deg, rgba(255, 181, 32, 0.93) 3%, rgba(239, 39, 153, 0.93) 88%, rgba(237, 18, 171, 0.93) 100%);
  opacity: .9;
}

h1,
h2,
h3 {
  color: #FFC145;
  font-size: 2em;
}

p {
  color: #F1E9DA;
}

@keyframes animate {

  0%,
  100% {
    filter: hue-rotate(0deg);
  }

  50% {
    filter: hue-rotate(360deg);
  }
}