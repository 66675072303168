.navbar {
  position: fixed;
  width: 5rem;
  height: 100vh;
  background-color: var(--bg-primary);
  transition: width 200ms ease;
  z-index: 100;

  &:hover {
    width: 16rem;

    .logo svg {
      transform: translateX(10em) rotate(180deg);
    }

    .link-text {
      visibility: visible;
      opacity: 1;
      transition-delay: 1s;
      transition: opacity .5s;
      left: 0;
    }

    .logo-text {
      opacity: 1;
      transition: opacity 2s;
    }
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    height: 100%;

    .nav-item {
      width: 100%;
    }


  }

  .nav-link{
    display: flex;
    align-items: center;
    position: relative;
    height: 5rem;
    color: var(--bg-secondary);
    text-decoration: none;
    filter: grayscale(100%) opacity(0.7);
    transition: var(--transition-speed);
    cursor: pointer;

    &:hover {
      filter: grayscale(0%) opacity(1);
      background: var(--bg-secondary);
      color: var(--text-secondary);
    }

    svg {
      min-width: 2rem;
      max-width: 4rem;
      margin: 0 1.5rem;
    }

    .link-text {
      visibility: hidden;
      margin-left: 1rem;
      opacity: 0;
    }
  }
}

.logo {
  left: 0;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: center;
  color: var(--text-primary);
  background: var(--bg-secondary);
  font-size: 1.1rem;
  letter-spacing: 0.2ch;
  width: 100%;

  .logo-text {
    color: var(--text-secondary);
    opacity: 0;
  }

  svg {
    position: absolute;
    height: 2.5em;
    width: 2.5em;
    transform: rotate(0deg);
    transition: transform 200ms;
  }
}

.navbar:hover .link-text {
  visibility: visible;
  opacity: 1;
  transition-delay: 1s;
  transition: opacity .5s;
  left: 0;
}

.navbar:hover .logo-text {
  opacity: 1;
  transition: opacity 2s;
}

.fa-primary {
  color: #ff7eee;
}

.fa-secondary {
  color: #df49a6;
}

.fa-primary,
.fa-secondary {
  transition: var(--transition-speed);
}