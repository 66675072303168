.skills-wrap {
  position: relative;
  margin-top: 4em;

  h3 {
    margin: 0;
    top: -2em;
    position: absolute;
  }

  .skills {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;

    .skill {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1em 2em 0 2em;
      list-style: none;

      .icon {
        height: 4em;
        width: 4em;
        border-radius: 5px;
      }

      p {
        margin: 1em 0 0 0;
      }
    }
  }
}